

import React from 'react'
import SeoPage from '../components/SeoPage'



const ContentPage18 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Trouvez le meilleur avocat pour vous en droit familial : Soumission gratuite - Soumissions Avocat"
        description="Vous recherchez un avocat fiable pour l’une des raisons suivantes? adoption divorce/séparation droit de garde/pension alimentaire mandat en cas d’inaptitude mariage/union civile médiation familiale procuration spécifique/générale succession/testament/fiducie tutelle/curatelle autre L’équipe de Soumissions Avocat regroupe pour vous des avocats fiables et compétents pour que que vous puissiez avoir l’esprit tranquille en LIRE PLUS"
    >
        <h1>Trouvez le meilleur avocat pour vous en droit familial : Soumission gratuite</h1><p><strong>Vous recherchez un avocat fiable pour l’une des raisons suivantes?</strong></p>
        <ul>
            <li>adoption</li>
            <li>divorce/séparation</li>
            <li>droit de garde/pension alimentaire</li>
            <li>mandat en cas d’inaptitude</li>
            <li>mariage/union civile</li>
            <li>médiation familiale</li>
            <li>procuration spécifique/générale</li>
            <li>succession/testament/fiducie</li>
            <li>tutelle/curatelle</li>
            <li>autre</li>
        </ul>
        <p>L’équipe de Soumissions Avocat regroupe pour vous des avocats fiables et compétents pour que que vous puissiez avoir l’esprit tranquille en confiant votre cause à un professionnel. Laissez-nous vous mettre avec un avocat dans votre secteur qui détient réellement l’expertise donc vous avez besoin pour mettre toute les chances de votre côté. <strong>Pour ce faire, remplissez simplement le formulaire sur cette page pour recevoir une offre de service gratuitement, rapidement et sans engagement de votre part.</strong></p>
    </SeoPage>
)
export default ContentPage18
